class Footer extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = `
      <div class="container">
        <hr>
        <div class="logo-footer text-center">
          <div class="logo">
            <h1>Jokers &amp; Queens</h1>
            <h2>Pop • Jazz • Latin • Blues</h2>
          </div>
          <br><br>
          <a href="kontakt.html" class="btn btn-primary">Jetzt anfragen!</a>
        </div>
      </div>
      <footer>
        <div class="container jq-footer">
          <div class="row">
            <div class="col-12 col-md-6 copyright">
              &copy; Jokers & Queens 2022
            </div>
            <div class="col-12 col-md-6">
              <ol>
                <li><a href="/datenschutz.html">Datenschutz</a></li>
                <li><a href="/impressum.html">Impressum</a></li>
              </ol>
            </div>
          </div>
        </div>
      </footer>
    `;
  }
}

customElements.define('footer-component', Footer);
